@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    color: #333333;
    counter-reset: order-flow-steps;
  }
  * {
    box-sizing: border-box;
    transition: all 0.15s ease-out;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:focus,
  select:focus {
    box-shadow: none !important;
    outline-width: 0 !important;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
@layer components {
  .order-flow-step.disabled {
    @apply opacity-50;
  }
  .order-flow-step-title {
    @apply p-4 w-full flex items-center border-dark-primary justify-between bg-[#F0F0F066] data-[hover]:bg-[#F0F0F0] data-[focus]:border-t data-[hover]:border-t;
  }
  .order-flow-step-title:not([data-open='false']) {
    @apply border-t bg-[#F0F0F0];
  }

  .order-flow-step-title h3 {
    @apply font-semibold text-base;
  }
  .order-flow-step-title h3::before {
    @apply font-semibold text-base;
    counter-increment: order-flow-steps;
    content: counter(order-flow-steps) '. ';
  }

  .order-flow-step-title button {
    color: #828282;
    opacity: 0.4;
  }
  .order-flow-step-title:hover button,
  .order-flow-step-title:focus button {
    opacity: 1;
  }
  .order-flow-step-body {
    @apply p-5 w-full space-y-6 text-sm;
  }

  .animate-dropdown {
    @apply origin-top transition duration-150 ease-out data-[closed]:scale-y-0 data-[closed]:opacity-0;
  }

  .steps {
    @apply w-full max-w-xl mx-auto;
  }
  .steps .step {
    @apply min-w-12 lg:min-w-16;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
    transition: box-shadow 200ms cubic-bezier(0.33, 1, 0.68, 1);
  }
  .sticky-header.active {
    box-shadow: hsla(0 0% 0% / 0.1) 0 0.5rem 1rem;
    transition-duration: 400ms;
  }

  .grid-container--fill {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }

  .data-table-wrapper {
    @apply w-full overflow-x-auto max-h-[80vh];
  }
  .data-table-wrapper--flat {
    @apply border-none;
  }
  .data-table-wrapper--elevated {
    @apply border border-[#EFEFEF] rounded-lg;
  }

  .data-table-wrapper table {
    @apply w-full z-0 text-left text-dark-primary;
  }

  .data-table-wrapper table thead tr th {
    @apply bg-white text-[13px] px-1 py-3 font-medium capitalize whitespace-nowrap text-ellipsis border-b border-[#EFEFEF];
  }

  .data-table-wrapper table.table--hover tbody tr {
    @apply cursor-pointer;
  }

  .data-table-wrapper table thead tr th:first-of-type,
  .data-table-wrapper table tbody tr td:first-of-type {
    @apply sticky left-0;
  }
  /* .data-table-wrapper table tbody tr td:first-of-type::after,
  .data-table-wrapper table tbody tr td#data-table-wrapper_menu::after {
    content: '';
    @apply absolute bottom-0 left-0 w-full h-[0.1px] bg-[#EFEFEF] -z-[1];
  } */

  .data-table-wrapper table thead tr th#data-table-wrapper_menu-header,
  .data-table-wrapper table tbody tr td.data-table-wrapper_menu {
    @apply sticky right-0;
  }

  .data-table-wrapper table thead tr {
    @apply sticky top-0 z-10;
  }

  .data-table-wrapper
    table
    tbody
    tr:not(:last-of-type)
    td {
    @apply border-b border-[#EFEFEF];
  }

  .data-table-wrapper table tbody tr td {
    @apply bg-white text-[13px] px-1 py-2 transition-all capitalize;
  }

  .data-table-wrapper table.table--striped tbody tr:nth-child(odd) td {
    @apply bg-[#fcfbfc];
  }

  .data-table-wrapper table.table--hover tbody tr:hover td {
    @apply bg-g-400;
  }

  .card.is-loading {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  @media screen and (max-width: 576px) {
    .btn {
      @apply px-2 min-h-[2.5rem];
    }
  }
  .label {
    @apply w-full;
  }
  .label .label-text {
    @apply text-[#2E3034] capitalize w-full;
  }

  .input {
    @apply bg-white border-[#CBCBCB] h-fit min-h-[unset] text-sm;
  }
  .input-disabled,
  input[disabled] {
    @apply bg-white border-[#CBCBCB] text-opacity-100 opacity-70;
  }
  .select {
    @apply bg-white border-[#CBCBCB] h-fit min-h-[unset] text-sm py-2;
    background-image: url('assets/dropdown-caret.svg');
    background-position: calc(100% - 10px) calc(1px + 50%),
      calc(100% - 16px) calc(1px + 50%);
    background-size: 8px 8px, 8px 8px;
    background-repeat: no-repeat;
    font-weight: inherit;
  }
  .select-disabled,
  .select[disabled] {
    @apply bg-white border-[#CBCBCB] text-opacity-100 opacity-70;
  }

  .btn {
    @apply capitalize font-normal text-dark-primary gap-1;
  }

  .btn-icon {
    @apply p-2 flex bg-transparent rounded-full transition-all gap-1 items-center justify-center;
  }

  button[disabled],
  button[disabled]:hover,
  .btn[disabled] *,
  .btn-disabled *,
  .btn[disabled]:hover *,
  .btn-disabled:hover * {
    @apply cursor-not-allowed;
  }
  .btn-outline.btn-primary:hover,
  .btn-outline.btn-secondary:hover,
  .btn-outline.btn-error:hover {
    @apply text-white;
  }
  .btn-primary:not(.btn-outline) {
    @apply bg-[#FF4D00] text-white;
  }
  .btn-secondary:not(.btn-outline) {
    @apply bg-black text-white;
  }
  .btn-error:not(.btn-outline) {
    @apply text-white;
  }
  .btn-rounded {
    @apply rounded-[100px];
  }

  .pagination-button {
    @apply p-2 rounded-[5px] border-2 border-[#D7DDE5] bg-[transparent] text-[#2F3A36];
  }
  .pagination-button.active {
    @apply bg-[#FF4D00] text-white border-[#FF4D00];
  }
  .pagination-btn {
    @apply p-2 rounded-full bg-transparent hover:bg-g-100;
  }
  .pagination-btn:disabled {
    @apply opacity-40 hover:bg-transparent;
  }

  .steps {
    @apply flex flex-row flex-wrap justify-center;
  }

  .steps .step:before {
    height: 0;
  }
  .steps .step:after {
    background-color: #f2f2f2;
    color: #ff4d00;
  }
  .steps .step-primary + .step-primary:before,
  .steps .step-primary:after {
    background-color: #ff4d00;
    color: #ffffff;
  }
  .search__group {
    @apply form-control flex flex-row divide-x h-[2.5rem] border items-stretch text-dark-primary overflow-x-hidden transition-all;
  }
  .search__group input {
    border: none;
  }

  .server-pagination-wrapper select:focus,
  .search__group input:focus,
  .search__group select:focus {
    border: none;
    border-width: 0;
    box-shadow: none;
    outline-width: 0;
  }
  .search__group__wrapper--float {
    @apply absolute top-0 z-20 duration-75 text-sm;
  }
  .search__group__wrapper--float.right {
    @apply right-0;
  }
  .search__group__wrapper--float.left {
    @apply left-0;
  }
  .radio:checked {
    background-color: hsl(var(--p) / var(--tw-bg-opacity)) !important;
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset !important;
    border-color: hsl(var(--p) / var(--tw-border-opacity)) !important;
  }
  input[type='checkbox']:enabled:checked {
    background-color: hsl(var(--p) / var(--tw-bg-opacity)) !important;
  }
  .tab {
    @apply text-dark-primary/60 hover:text-dark-primary/80;
  }
  .tab-active {
    color: #ffffff !important;
  }
  .nav-link {
    background-color: #ffffff !important;
  }
  .nav-link:hover {
    background-color: hsl(var(--bc) / var(--tw-bg-opacity)) !important;
  }

  .tracking-modal__background {
    background-image: url('./assets/map.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .pdf-preview__items__item {
    @apply pl-2 w-[20%] border-2;
  }
  .pdf-preview__items__item--local {
    @apply pl-2 w-[25%] border-2;
  }

  .no_data_description_text {
    @apply text-gray-500 font-semibold text-sm;
  }
}

.ReactModal__Overlay {
  z-index: 10;
}

.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #fe6802 !important;
}

.package-item .css-1c35hjw-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.4375em;
}
